<template>
  <div class="status" :class="nameplate[product.status].class">
    <img
      class="status__image"
      :class="nameplate[product.status].class"
      :src="`/img/product-statuses/${nameplate[product.status].icon}`"
      :alt="_T(nameplate[product.status].title)"
    />
    <div class="status__titles">
      <span class="status__title" :class="nameplate[product.status].class">
        {{ _T(nameplate[product.status].title) }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
});

const nameplateType = {
  inStock: "inStock",
  quickProduction: "quickProduction",
  preOrder: "preOrder",
  outOfStock: "outOfStock",
  outOfProduction: "outOfProduction",
};

const nameplate = {
  [nameplateType.inStock]: {
    title: "@Available",
    icon: "product-in-stock.svg",
    class: "in-stock",
  },
  [nameplateType.quickProduction]: {
    title: "@Available",
    icon: "product-in-stock.svg",
    class: "in-stock",
  },
  [nameplateType.preOrder]: {
    title: "@Pre-order",
    icon: "product-preorder.svg",
    class: "pre-order",
  },
  [nameplateType.outOfStock]: {
    title: "@Not available",
    icon: "product-out-of-stock.svg",
    class: "not-available",
  },
  [nameplateType.outOfProduction]: {
    title: "@Out of production",
    icon: "product-out-of-stock.svg",
    class: "not-available",
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: max-content;

  @include flex-container(row, center, center);

  border-radius: 30px;

  padding: 4px 8px;
  gap: 8px;

  @include mobile {
    gap: 4px;
  }

  &.in-stock {
    background-color: #d0ead3;
  }

  &.not-available {
    background-color: #e9e9e9;
  }

  &.pre-order {
    background-color: #d8f1ff;
  }

  &__image {
    @include mobile {
      @include fixedHW(16px);
    }
  }

  &__title {
    white-space: nowrap;
    @include font(12, 16);
    letter-spacing: 0.02em;

    @include mobile {
      @include font(10, 12);
    }

    &.in-stock {
      color: #159822;
    }

    &.not-available {
      color: #393d38;
    }

    &.pre-order {
      color: #4490bb;
    }
  }
}
</style>
